<template>
    <div>
        <v-chip v-if="paymentValue > 0" class="ma-2" color="success" text-color="white">
            <v-avatar left> <v-icon>done</v-icon> </v-avatar>Pago R${{ paymentValue.toFixed(2) }} {{ getCardInfo }}
        </v-chip>
        <v-chip v-else-if="paymentValue == 0" class="ma-2" color="grey" text-color="white">
            <v-avatar left> <v-icon>grade</v-icon> </v-avatar>Grátis
        </v-chip>
        <v-chip v-else class="ma-2" color="error" text-color="white">
            <v-avatar left> <v-icon>error</v-icon> </v-avatar>Pendente
        </v-chip>
    </div>
</template>

<script>
export default {
    props: {
        paymentValue: {
            required: true,
        },
        payment: {
            required: true,
        },
    },
    computed: {
        getCardInfo() {
            return this.payment && this.payment.last4 ? `(*** ${this.payment.last4})` : "";
        },
    }
};
</script>