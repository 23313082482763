<template>
    <v-container grid-list-xl fluid>
        <h1>Relatório de Carregamentos</h1>
        <v-divider class="mb-4" />
        <v-layout wrap>
            <v-flex xs12 sm6>
                <DateInterval @onChange="change" />
            </v-flex>
            <v-flex xs12 sm6>
                <v-autocomplete v-model="selectedCharger" :items="charges" item-text="name" item-value="name" label="Filtrar por carregador" return-object="true"
                    :disabled="$store.getters.isLoading" :loading="$store.getters.isLoading" clearable />
            </v-flex>
        </v-layout>
        <v-layout wrap>
            <v-flex xs12 sm6>
                <v-card color="primary">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>timer</v-icon>
                            {{ dateTime.showMinutes(total) }}
                        </div>
                        Tempo Conectado
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 sm6>
                <v-card color="primary">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>score</v-icon>
                            {{ listWithFilter.length }}
                        </div>
                        Número de carregamentos
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 sm12 v-if="items.length == 0">
                <v-alert type="error"> Nenhum dado encontrado para esse perído de tempo. </v-alert>
            </v-flex>
            <v-flex xs12 v-if="items.length > 0">
                <v-data-table :headers="headers" :items="listWithFilter" :items-per-page="-1" class="elevation-2" hide-default-footer :loading="!items">
                    <v-progress-linear v-slot:progress color="blue" indeterminate />
                    <template v-slot:[`item.bike.plug`]="{ item }">{{ item.bike.plug }}</template>
                    <template v-slot:[`item.bike.minutesConnected`]="{ item }">{{ dateTime.showMinutes(item.bike.minutesConnected) }}</template>
                    <template v-slot:[`item.paymentValue`]="{ item }">
                        <ChipPaymentValue :paymentValue="item.paymentValue" :payment="item.payment" />
                    </template>
                </v-data-table>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import dateTime from "@/helpers/DateTime";
import DateInterval from "@/components/core/DateInterval";
import ChipPaymentValue from "@/components/shared/Payment/ChipPaymentValue";

export default {
    components: { DateInterval, ChipPaymentValue },

    data() {
        return {
            dateTime,
            selectedCharger: null,
            charges: [],
            headers: [
                { text: "Data", value: "dateTime", align: "center" },
                { text: "Tempo Conectado", value: "bike.minutesConnected", align: "center" },
                { text: "Status Pagamento", value: "paymentValue", align: "center" },
                { text: "Plug", value: "bike.plug", align: "center" },
                { text: "Carregador", align: "left", value: "charger.name", visibility: false },
            ],
            items: [],
        };
    },

    methods: {
        change(dates) {
            let query = dates.length == 2 ? `?dateFrom=${dates[0]}&dateTo=${dates[1]}` : `?dateFrom=${dates[0]}&dateTo=${dates[0]}`;

            this.$http
                .get("api/v2/bike/charge/" + this.$store.state.user.ID + query)
                .then((result) => {
                    this.selectedCharger = null;
                    this.charges = [];
                    result.forEach((item) => {
                        this.charges.push(item.charger.name);
                    });
                    this.items = result;
                })
                .catch(() => {
                    this.charges = [];
                    this.items = [];
                });
        },
    },

    computed: {
        listWithFilter() {
            if (this.selectedCharger) {
                return this.items.filter((item) => item.charger.name == this.selectedCharger);
            } else {
                return this.items;
            }
        },
        total() {
            return Number.parseFloat(
                this.listWithFilter.reduce(function (sum, i) {
                    return sum + i.bike.minutesConnected;
                }, 0)
            ).toFixed(2);
        },
    },
};
</script>